import Image from "next/image";
import React, { useState } from "react";
import { FiX } from "react-icons/fi";

const Advertisement = ({
  url = "#",
  src = "/temp/add-row.png",
  width = "900",
  height = "110",
  className,
  style,
  isPlaceholder = false,
}) => {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <div
          style={{ style }}
          className={`flex justify-center ${className ? className : ""}`}
        >
          <div className="relative">
            <button
              onClick={() => setShow(false)}
              className="absolute right-[1px] top-[1px] bg-white z-10 hover:text-red-600 transition-all"
            >
              <FiX />
            </button>
            <a href={url} rel="nofollow" target="_blank">
              <Image
                alt="Advertisement"
                src={src}
                width={width}
                height={height}
                style={{ height: height }}
              />
            </a>

            {isPlaceholder && (
              <div
                className="absolute text-white text-[50px] font-semibold z-10 w-full text-center"
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {width} x {height}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Advertisement;
