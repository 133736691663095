import Skeleton from "@unisearch/common/skeleton";
import styles from "./loader.module.scss";

const HomeStudentLoader = ({ isImageRight }) => (
  <section>
    <div className="container">
      <div className={styles.grid}>
        <Skeleton
          className={styles.image}
          style={{ order: isImageRight ? "1" : "-1" }}
        />
        <div>
          <Skeleton className={styles.title} />
          <Skeleton className={styles.sub_title} />
          <Skeleton className={styles.button} />
        </div>
      </div>
    </div>
  </section>
);

export default HomeStudentLoader;
