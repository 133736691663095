import { searchPopupState } from "@_recoil/_state/search_popup";
import { Modal } from "@mui/material";
import { getAssets } from "@unisearch/helpers";
import { LayoutMain } from "@unisearch/theme/layout";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useInView } from "react-intersection-observer";
import { useRecoilState } from "recoil";

import CountdownLoader from "@modules/study/common/sections/countdown_two/loader";
import HomeBannerLoader from "@modules/study/home/banner/loader";
import HomeBestUniversityLoader from "@modules/study/home/find_university/loader";
import HomeThepressLoader from "@modules/study/home/footer_carousel/loader";
import HomeTabLoader from "@modules/study/home/tab/loader";
import HomeStudentLoader from "@modules/study/home/unisearch_student/loader";
import AdvertisementRow from "@unisearch/common/advertisement/row";

const VirtualOfficePopup = dynamic(
  () => import("@modules/study/common/virtual_office_popup"),
  {
    ssr: false,
  }
);
const HomeBanner = dynamic(() => import("@modules/study/home/banner"), {
  loading: () => <HomeBannerLoader />,
});
const HomeTab = dynamic(() => import("@modules/study/home/tab"), {
  ssr: false,
  loading: () => <HomeTabLoader />,
});
const CountdownTwo = dynamic(
  () => import("@modules/study/common/sections/countdown_two"),
  {
    ssr: false,
    loading: () => <CountdownLoader />,
  }
);
const FindUniversity = dynamic(
  () => import("@modules/study/home/find_university"),
  {
    ssr: false,
    loading: () => <HomeBestUniversityLoader />,
  }
);
const UnisearchStudent = dynamic(
  () => import("@modules/study/home/unisearch_student"),
  {
    loading: () => <HomeStudentLoader />,
  }
);
const UnisearchUniversity = dynamic(
  () => import("@modules/study/home/unisearch_university"),
  {
    loading: () => <HomeStudentLoader isImageRight />,
  }
);
const FooterCarousel = dynamic(
  () => import("@modules/study/home/footer_carousel"),
  {
    ssr: false,
    loading: () => <HomeThepressLoader />,
  }
);

const backgroundImage = getAssets("assets/images/home/slice-left.webp");

const Home = () => {
  const [_, setPopupState] = useRecoilState(searchPopupState);
  const [open, setOpen] = useState(false);
  const [popupCloseState, setPopupCloseState] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (window !== undefined) {
      Cookies.set("referrer", document.referrer);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setPopupCloseState(true);
    setPopupState((prev) => ({ ...prev, isOpenHomePagePopup: false }));
  };

  useEffect(() => {
    if (inView && !popupCloseState) {
      setOpen(true);
      setPopupState((prev) => ({ ...prev, isOpenHomePagePopup: true }));
    }
  }, [inView, popupCloseState, setPopupState]);

  const jsonLd = () => {
    return {
      __html: `
      [
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "UniSearch",
        "alternateName": "UniSearch",
        "url": "https://myunisearch.com/",
        "logo": "${process.env.NEXT_PUBLIC_URL}/assets/logo_dark.png",
        "sameAs": [
          "https://www.facebook.com/UniSearchIndia",
          "https://twitter.com/myunisearch",
          "https://www.youtube.com/channel/UCCHQrxOr5olDBxypJUE7qZw",
          "https://myunisearch.com/"
        ]
        },
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "UniSearch",
            "url": "https://myunisearch.com/",
            "potentialAction": {
                "@type": "SearchAction",
                "target": {
                    "@type": "EntryPoint",
                    "urlTemplate": "https://myunisearch.com/search?ref=general\u0026refq={search_term_string}\u0026limit=10\u0026page=1"
                },
                "query-input": "required name=search_term_string"
            }
        }
      ]`,
    };
  };

  return (
    <>
      <LayoutMain
        footer_with_evaluation={true}
        fixedPopup={false}
        title=""
        metaTitle=""
        metaKeywords="unisearch, study abroad, apply university, study"
        metaDescription={
          "Bringing international student experience and intelligent technology together to make study abroad solutions simpler than ever"
        }
        metaImage={getAssets("assets/home.png")}
        metaUrl={process.env.NEXT_PUBLIC_URL}
        JsonLd={jsonLd}
      >
        <HomeBanner />
        {/* ad */}
        <div className="lg:mt-[-50px] mb-5">
          <AdvertisementRow
            srcDesktop="/ad/study-in-au.webp"
            srcMobile="/ad/study-in-au-mobile.webp"
            url="https://myunisearch.com/lead/apply-for-scholarships-at-the-victoria-university"
          />
        </div>

        <HomeTab />
        <CountdownTwo />
        <FindUniversity />

        {/* ad */}
        <div className="mt-7 lg:mt-[-50px] lg:mb-[-32px]">
          <AdvertisementRow
            srcDesktop="/ad/study-in-au-v2.webp"
            srcMobile="/ad/study-in-au-mobile.webp"
            url="https://myunisearch.com/lead/apply-for-scholarships-at-the-victoria-university"
          />
        </div>

        <div ref={ref}></div>
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left bottom",
          }}
        >
          <UnisearchStudent />
          <UnisearchUniversity />
        </div>
        <FooterCarousel />
      </LayoutMain>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <div className="modal sm">
          <button
            type="button"
            style={{
              position: "absolute",
              top: "24px",
              right: "10px",
              zIndex: 10,
            }}
            className="cross_icon"
            onClick={handleClose}
          >
            <FiX />
          </button>
          <VirtualOfficePopup />
        </div>
      </Modal>
    </>
  );
};

export default Home;
