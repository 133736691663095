import Skeleton from "@unisearch/common/skeleton";
import styles from "./loader.module.scss";

const HomeThepressLoader = () => (
  <section>
    <div className="container">
      <Skeleton className={styles.title} />
      <Skeleton className={styles.press} />
      <Skeleton className={styles.press} />
    </div>
  </section>
);

export default HomeThepressLoader;
