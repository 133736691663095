import Skeleton from "@unisearch/common/skeleton";
import styles from "./loader.module.scss";

const CountdownLoader = () => (
  <div className="container">
    <div className={styles.grid}>
      <Skeleton className={styles.counter} />
      <div className={styles.content}>
        <Skeleton className={styles.heading} />
        <Skeleton className={styles.text} />
      </div>
    </div>
  </div>
);

export default CountdownLoader;
