import Skeleton from "@unisearch/common/skeleton";
import styles from "./loader.module.scss";

const HomeBestUniversityLoader = () => (
  <section>
    <div className="container">
      <Skeleton className={styles.title} />
      <Skeleton className={styles.sub_title} />

      <div className={styles.grid}>
        {new Array(28).fill(1).map((_, i) => {
          return <Skeleton key={i} height={70} />;
        })}
      </div>
    </div>
  </section>
);

export default HomeBestUniversityLoader;
