import Skeleton from "@unisearch/common/skeleton";
import styles from "./loader.module.scss";

const HomeTabLoader = () => (
  <div className="container">
    <div className={styles.tab}>
      {new Array(3).fill(1).map((_, index) => {
        return <Skeleton key={index} height={70} />;
      })}
    </div>
    <div className={styles.grid}>
      <div className={styles.content}>
        <Skeleton height={140} style={{ marginBottom: "20px" }} />
        <Skeleton height={300} />
      </div>
      <Skeleton height={350} />
    </div>
  </div>
);

export default HomeTabLoader;
