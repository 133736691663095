import Advertisement from "..";
import useWindowDimensions from "_hooks/useWindowDimensions";

const AdvertisementRow = ({
  srcDesktop = "/temp/add-row.png",
  srcMobile = "/temp/add-column-sm.png",
  url = "#",
}) => {
  const { width } = useWindowDimensions();
  if (width > 900) {
    return <Advertisement url={url} width={920} height={90} src={srcDesktop} />;
  } else {
    return <Advertisement url={url} width={336} height={280} src={srcMobile} />;
  }
};
export default AdvertisementRow;
