import Skeleton from "@unisearch/common/skeleton";
import styles from "./loader.module.scss";

const HomeBannerLoader = () => (
  <div className="container">
    <div className={styles.grid}>
      <div>
        <Skeleton className={styles.title} />
        <Skeleton className={styles.sub_title} />
        <Skeleton className={styles.search} />
      </div>
      <div>
        <Skeleton height={520} />
      </div>
    </div>

    <div className={styles.features}>
      {new Array(6).fill(1).map((_, i) => {
        return <Skeleton key={i} height={172} />;
      })}
    </div>
  </div>
);

export default HomeBannerLoader;
